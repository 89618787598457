import { IonButton, IonButtons, IonHeader, IonTitle, IonToolbar } from "@ionic/react";
import { useEffect } from "react";
import "./standardModalHeader.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";

interface IStandardModalHeaderProps {
    title: string;
    onCloseClick: () => void;
}

export const StandardModalHeader = (props: IStandardModalHeaderProps) => {
    const history = useHistory();
    useEffect(() => {
        const unlisten = history?.listen(() => {
            props.onCloseClick();
        });
        return unlisten;
        // eslint-disable-next-line   react-hooks/exhaustive-deps
    }, []);

    return (
        <IonHeader className={"standard-modal-header"}>
            <IonToolbar>
                <IonTitle className={"standard-modal-header__title"} slot={"start"}>
                    {props.title}
                </IonTitle>
                <IonButtons slot="end">
                    <IonButton onClick={props.onCloseClick}>
                        <FontAwesomeIcon icon={faTimes} size="lg" className="standard-modal-header__icon" />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
};
