import { MachineCounterTracking } from "@farmact/model/src/model/MachineCounterTracking";
import { RentalOrder, RentalOrderMachineCounterPriceTracking } from "@farmact/model/src/model/RentalOrder";
import { IonButton, IonButtons, IonContent, IonFooter, IonToolbar } from "@ionic/react";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Firebase } from "../../../../../../../firebase";
import { useCollectionData } from "../../../../../../../firebase/dataHooks";
import { getMachineCounterTypeName } from "../../../../../../../util/nameUtils/machineCounterTrackingNameUtils";
import { DateTimeInput } from "../../../../../../inputs/DateTimeInput/DateTimeInput";
import * as FormControl from "../../../../../../inputs/FormControl";
import { Input } from "../../../../../../inputs/Input/Input";
import { useOrganizationContext } from "../../../../../../organization/context/OrganizationContext";
import { StandardModalHeader } from "../../../../../../structure/StandardModalHeader/StandardModalHeader";
import { useRentalOrderDetailsPageContext } from "../../../../../RentalOrderDetailsPage/RentalOrderDetailsPageContext";
import "./editRentalOrderMachinePriceModal.scss";
import { useEditRentalOrderMachinePriceModalValidation } from "./useEditRentalOrderMachinePriceModalValidation";
import { FarmActModal } from "../../../../../../FarmActModal/FarmActModal";

interface IEditRentalOrderMachinePriceModalProps {
    isOpen: boolean;
    onDismiss: () => void;

    priceTracking: RentalOrderMachineCounterPriceTracking;
    onSubmit: (updateData: Partial<RentalOrderMachineCounterPriceTracking>) => void;
}

export const EditRentalOrderMachinePriceModal = (props: IEditRentalOrderMachinePriceModalProps) => {
    const { rentalOrder } = useRentalOrderDetailsPageContext();
    const { machines } = useOrganizationContext();

    const [lastMachineCounterTracking] = useCollectionData(
        Firebase.instance().getLastMachineCounterTracking(
            props.priceTracking.machineId,
            props.priceTracking.machineCounterType
        ),
        [props.priceTracking.machineId, props.priceTracking.machineCounterType]
    );
    const lastMachineCounterTrackingValue = getLatestMachineCounterTrackingValue(
        lastMachineCounterTracking,
        rentalOrder
    );

    const [previousOpen, setPreviousOpen] = useState(false);
    const [state, setState] = useState<EditRentalOrderMachinePriceState>(INITIAL_STATE);

    if (props.isOpen !== previousOpen) {
        if (props.isOpen) {
            setState(inferPriceTrackingState(props.priceTracking));
        } else {
            setState(INITIAL_STATE);
        }

        setPreviousOpen(props.isOpen);
    }

    const validation = useEditRentalOrderMachinePriceModalValidation(state);

    const machine = machines.find(machine => {
        return machine.id === props.priceTracking.machineId;
    });

    const updateState = (updateData: Partial<EditRentalOrderMachinePriceState>) => {
        setState(prev => ({
            ...prev,
            ...updateData,
        }));
    };
    const getNumberValueChangeHandler = (
        property: "startValue" | "endValue"
    ): React.ChangeEventHandler<HTMLInputElement> => {
        return event => {
            const value = event.currentTarget.valueAsNumber;
            updateState({
                [property]: Number.isNaN(value) ? null : value,
            });
        };
    };
    const handleDismiss = () => {
        validation.reset();
        props.onDismiss();
    };
    const handleSubmit = () => {
        if (!validation.validate()) {
            return;
        }

        props.onSubmit({
            startDateTime: state.startDate?.toISOString() ?? null,
            startValue: state.startValue,
            endDateTime: state.endDate?.toISOString() ?? null,
            endValue: state.endValue,
        });
    };

    const machineCounterName = getMachineCounterTypeName(props.priceTracking.machineCounterType);

    return (
        <FarmActModal isOpen={props.isOpen} onDidDismiss={handleDismiss} className="edit-rental-order-machine-price-modal">
            <div className="default-modal-container">
                <StandardModalHeader title="Maschinenzähler eingeben" onCloseClick={handleDismiss} />
                <IonContent>
                    <div className="edit-rental-order-machine-price-modal__content">
                        <div className="edit-rental-order-machine-price-modal__header">
                            <div className="edit-rental-order-machine-price-modal-header">
                                <span className="edit-rental-order-machine-price-modal-header__title">Maschine</span>
                                <span className="edit-rental-order-machine-price-modal-header__value">
                                    {machine?.name}
                                </span>
                            </div>

                            <div className="edit-rental-order-machine-price-modal-header">
                                <span className="edit-rental-order-machine-price-modal-header__title">Zähler</span>
                                <span className="edit-rental-order-machine-price-modal-header__value">
                                    {machineCounterName}
                                </span>
                            </div>
                        </div>

                        <div className="edit-rental-order-machine-price-modal__section">
                            <FormControl.Root>
                                <FormControl.Label className="edit-rental-order-machine-price-modal__start-value-label">
                                    Startwert
                                    {lastMachineCounterTrackingValue !== null && (
                                        <span className="edit-rental-order-machine-price-modal-start-value-label__hint">
                                            Zuletzt: {formatPreviousValue(lastMachineCounterTrackingValue)}
                                        </span>
                                    )}
                                </FormControl.Label>
                                <Input
                                    type="number"
                                    error={validation.errors.startValue}
                                    placeholder={`${machineCounterName} (Start)`}
                                    fullWidth
                                    value={state.startValue ?? ""}
                                    onChange={getNumberValueChangeHandler("startValue")}
                                />
                            </FormControl.Root>

                            <FormControl.Root>
                                <FormControl.Label>Startzeit</FormControl.Label>
                                <DateTimeInput
                                    error={validation.errors.startDate}
                                    value={state.startDate}
                                    onValueChange={startDate => {
                                        updateState({ startDate });
                                    }}
                                />
                            </FormControl.Root>
                        </div>

                        <div className="edit-rental-order-machine-price-modal__section">
                            <FormControl.Root>
                                <FormControl.Label>Endwert</FormControl.Label>
                                <Input
                                    type="number"
                                    error={validation.errors.endValue}
                                    placeholder={`${machineCounterName} (Ende)`}
                                    fullWidth
                                    value={state.endValue ?? ""}
                                    onChange={getNumberValueChangeHandler("endValue")}
                                />
                            </FormControl.Root>

                            <FormControl.Root>
                                <FormControl.Label>Endzeit</FormControl.Label>
                                <DateTimeInput
                                    error={validation.errors.endDate}
                                    value={state.endDate}
                                    onValueChange={endDate => {
                                        updateState({ endDate });
                                    }}
                                />
                            </FormControl.Root>
                        </div>
                    </div>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton color="dark" onClick={handleDismiss}>
                                Verwerfen
                            </IonButton>
                        </IonButtons>
                        <IonButtons slot="end">
                            <IonButton color="primary" onClick={handleSubmit}>
                                Speichern
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            </div>
        </FarmActModal>
    );
};

export interface EditRentalOrderMachinePriceState {
    startDate: Date | null;
    startValue: number | null;
    endDate: Date | null;
    endValue: number | null;
}

const INITIAL_STATE: EditRentalOrderMachinePriceState = {
    startDate: null,
    startValue: null,
    endDate: null,
    endValue: null,
};

function inferPriceTrackingState(
    priceTracking: RentalOrderMachineCounterPriceTracking
): EditRentalOrderMachinePriceState {
    const startDate = priceTracking.startDateTime ? dayjs(priceTracking.startDateTime).toDate() : null;
    const endDate = priceTracking.endDateTime ? dayjs(priceTracking.endDateTime).toDate() : null;

    return {
        startDate,
        startValue: priceTracking.startValue,
        endDate,
        endValue: priceTracking.endValue,
    };
}

function formatPreviousValue(value: number): string {
    const formatter = new Intl.NumberFormat([], {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    });

    return formatter.format(value);
}

function getLatestMachineCounterTrackingValue(
    machineCounterTrackings: MachineCounterTracking[],
    rentalOrder: RentalOrder
): number | null {
    if (machineCounterTrackings.length === 0) {
        return null;
    }

    const latestNonRentalOrder = machineCounterTrackings.find(machineCounterTracking => {
        return machineCounterTracking.rentalOrderId !== rentalOrder.id;
    });

    return latestNonRentalOrder?.endValue ?? null;
}
