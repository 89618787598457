import { OrderStatus } from "@farmact/model/src/model/OrderStatus";
import { AnyRentalOrderPriceTracking, RentalOrderPriceTrackingType } from "@farmact/model/src/model/RentalOrder";
import clsx from "clsx";
import { getMachineCounterTypeName } from "../../../../../util/nameUtils/machineCounterTrackingNameUtils";
import { useRentalOrderDetailsPageContext } from "../../../RentalOrderDetailsPage/RentalOrderDetailsPageContext";
import { RentalOrderCustomPriceControl } from "./RentalOrderCustomPriceControl/RentalOrderCustomPriceControl";
import { RentalOrderMachinePriceControl } from "./RentalOrderMachinePriceControl/RentalOrderMachinePriceControl";
import "./rentalOrderPriceTrackingsMachineGroupRow.scss";

interface IRentalOrderPriceTrackingsMachineGroupRowProps {
    priceTracking: AnyRentalOrderPriceTracking;
    onPriceTrackingChange: (priceTracking: Partial<AnyRentalOrderPriceTracking>) => void;
}

export const RentalOrderPriceTrackingsMachineGroupRow = (props: IRentalOrderPriceTrackingsMachineGroupRowProps) => {
    const { rentalOrder } = useRentalOrderDetailsPageContext();

    const status = getPriceTrackingEditStatus(props.priceTracking);

    const isBilled = rentalOrder.status === OrderStatus.BILLED;

    return (
        <div
            className={clsx("rental-order-price-trackings-machine-group-row", {
                "rental-order-price-trackings-machine-group-row--draft": status === PriceTrackingEditStatus.DRAFT,
                "rental-order-price-trackings-machine-group-row--done": status === PriceTrackingEditStatus.DONE,
            })}>
            <div className="rental-order-price-trackings-machine-group-row__title">
                {formatPriceTrackingName(props.priceTracking)}
            </div>

            {props.priceTracking.type === RentalOrderPriceTrackingType.MACHINE_COUNTER && (
                <RentalOrderMachinePriceControl
                    priceTracking={props.priceTracking}
                    onPriceTrackingChange={props.onPriceTrackingChange}
                    disabled={isBilled}
                />
            )}
            {props.priceTracking.type === RentalOrderPriceTrackingType.CUSTOM_COUNTER && (
                <RentalOrderCustomPriceControl
                    priceTracking={props.priceTracking}
                    onPriceTrackingChange={props.onPriceTrackingChange}
                    disabled={isBilled}
                />
            )}
        </div>
    );
};

function formatPriceTrackingName(priceTracking: AnyRentalOrderPriceTracking): string {
    if (priceTracking.type === RentalOrderPriceTrackingType.MACHINE_COUNTER) {
        return getMachineCounterTypeName(priceTracking.machineCounterType);
    }

    return priceTracking.unit;
}
enum PriceTrackingEditStatus {
    DRAFT,
    DONE,
}
function getPriceTrackingEditStatus(priceTracking: AnyRentalOrderPriceTracking): PriceTrackingEditStatus {
    if (priceTracking.type === RentalOrderPriceTrackingType.MACHINE_COUNTER) {
        const { startDateTime, startValue, endDateTime, endValue } = priceTracking;
        if (startDateTime === null || startValue === null || endDateTime === null || endValue === null) {
            return PriceTrackingEditStatus.DRAFT;
        }

        return PriceTrackingEditStatus.DONE;
    }

    return priceTracking.value === null ? PriceTrackingEditStatus.DRAFT : PriceTrackingEditStatus.DONE;
}
