export function generateSearchableSubstrings(searchableStrings: string[]){
    const searchableSubstrings: string[] = [];
    for (const s of searchableStrings) {
        const lowerCasedCustomerAttribute = s.toLowerCase();
        const maxSubstringLength = Math.min(lowerCasedCustomerAttribute.length, 10);
        if (maxSubstringLength < 3) {
            continue;
        }
        for (let currentSubstringLength = 3; currentSubstringLength <= maxSubstringLength; currentSubstringLength++) {
            for (
                let currentStartIndex = 0;
                currentStartIndex <= lowerCasedCustomerAttribute.length - currentSubstringLength;
                currentStartIndex++
            ) {
                searchableSubstrings.push(
                    lowerCasedCustomerAttribute.substring(currentStartIndex, currentStartIndex + currentSubstringLength)
                );
            }
        }
    }
    return searchableSubstrings.filter((substring, index, allSubstrings) => allSubstrings.indexOf(substring) === index);
}