import { useCallback, useEffect, useRef, useState } from "react";

export type ValidationError = string | undefined;

export type Validator<Value> = (value: Value) => ValidationError;

export interface ValidationOptions {
    /**
     * @default true
     */
    default?: boolean;
    /**
     * Wether to enable validation. If disabled `validate()` and `isValid` return `default`
     * @default true
     */
    enabled?: boolean;
}

const DEFAULT_VALIDATION_OPTIONS: Partial<ValidationOptions> = {
    default: true,
    enabled: true,
};

export interface ValidationController {
    error: ValidationError;
    /**
     * should return true if the value is valid
     */
    validate: () => boolean;
    /**
     * Reset the validation to it's initial state
     */
    reset: () => void;
}

export function useValidation<Value>(
    value: Value,
    validator: Validator<Value>,
    options: ValidationOptions = DEFAULT_VALIDATION_OPTIONS
): ValidationController {
    const [error, setError] = useState<ValidationError>();

    const isFirstRender = useRef(true);

    const latestValidator = useRef(validator);
    latestValidator.current = validator;

    const latestOptions = useRef({
        ...DEFAULT_VALIDATION_OPTIONS,
        ...options,
    });
    latestOptions.current = {
        ...DEFAULT_VALIDATION_OPTIONS,
        ...options,
    };

    const validate = useCallback(() => {
        if (latestOptions.current.enabled) {
            const error = latestValidator.current(value);
            setError(error);
            return !error;
        }
        return !!latestOptions.current.default;
    }, [value]);

    const reset = useCallback(() => {
        setError(undefined);
        isFirstRender.current = true;
    }, []);

    useEffect(() => {
        if (!isFirstRender.current) {
            validate();
        }

        isFirstRender.current = false;
    }, [validate, value]);

    useEffect(() => {
        if (!options.enabled) {
            setError(undefined);
        }
    }, [options.enabled, options.default]);

    return {
        error,
        validate,
        reset,
    };
}
