import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { Interval } from "./intervalsOverlap";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(duration);

export function getTimeStringFromMinutes(minutes: number): string {
    minutes = Math.round(minutes);
    return minutes >= 0
        ? `${String(Math.floor(minutes / 60)).padStart(2, "0")}:${String(minutes % 60).padStart(2, "0")}`
        : `-${String(Math.floor(-minutes / 60)).padStart(2, "0")}:${String(-minutes % 60).padStart(2, "0")}`;
}

export function getTimeStringFromHours(hours: number): string {
    return getTimeStringFromMinutes(Math.round(hours * 60));
}

export function getElapsedTime(isoTimeString: string): string {
    const diff = dayjs().diff(dayjs(isoTimeString), "s");
    const hours = String(Math.floor(diff / 60 / 60)).padStart(2, "0");
    const minutes = String(Math.floor(diff / 60) % 60).padStart(2, "0");
    const seconds = String(diff % 60).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
}

export function compareDateTimes(dateTime1: string | undefined | null, dateTime2: string | undefined | null): number {
    if (!dateTime1 && !dateTime2) {
        return 0;
    }

    if (dateTime1 && !dateTime2) {
        return 1;
    }

    if (!dateTime1 && dateTime2) {
        return -1;
    }

    if (dateTime1 && dateTime2) {
        return dateTime1.localeCompare(dateTime2);
    }

    return 0;
}

export type DateRange = [from: Date, to: Date];

type GetDateRangeOptions = {
    /**
     * @default 1
     */
    stepValue?: number;
    /**
     * @default "hours"
     */
    stepUnit?: dayjs.ManipulateType;
    /**
     * @default false
     */
    inclusiveTo?: boolean;
};

/**
 * Get a list of dates between the given range
 * @param range
 * @param options
 * @returns
 */
export function getDateRange(range: DateRange, options?: GetDateRangeOptions): Date[] {
    const mergedOptions: Required<GetDateRangeOptions> = {
        stepValue: options?.stepValue ?? 1,
        stepUnit: options?.stepUnit ?? "hours",
        inclusiveTo: options?.inclusiveTo ?? false,
    };

    if (dayjs(range[0]).isAfter(range[1])) {
        return [];
    }

    const list: Date[] = [];
    let pointer: Date = range[0];

    const checkIsWithinRange = () => {
        if (mergedOptions.inclusiveTo) {
            return dayjs(pointer).isSameOrBefore(range[1]);
        }
        return dayjs(pointer).isBefore(range[1]);
    };

    while (checkIsWithinRange()) {
        list.push(pointer);
        pointer = dayjs(pointer).add(mergedOptions.stepValue, mergedOptions.stepUnit).toDate();
    }

    return list;
}

export function dateRangeToInterval(dateRange: DateRange): Interval {
    return {
        startDateTime: dateRange[0].toISOString(),
        endDateTime: dateRange[1].toISOString(),
    };
}

export type DateLike = Date | dayjs.Dayjs;

export function isValidDate(date: Date) {
    return !isNaN(date.getTime());
}

export function formatDurationToHours(duration: duration.Duration) {
    return `${String(Math.floor(duration.asHours())).padStart(2, "0")}:${String(duration.minutes()).padStart(2, "0")}`;
}

interface RestrictDateRangeParams {
    min?: string | number | Date | dayjs.Dayjs;
    max?: string | number | Date | dayjs.Dayjs;
}
export function restrictDateRange(range: DateRange, params: RestrictDateRangeParams): DateRange {
    const userRangeFrom = range[0];
    const userRangeTo = range[1];

    const nextRange: DateRange = [userRangeFrom, userRangeTo];

    if (params.min) {
        const displayedStartDateTimeMs = dayjs(params.min).toDate().getTime();
        nextRange[0] = new Date(Math.max(displayedStartDateTimeMs, userRangeFrom.getTime()));
    }
    if (params.max) {
        const displayedEndDateTimeMs = dayjs(params.max).toDate().getTime();
        nextRange[1] = new Date(Math.min(displayedEndDateTimeMs, userRangeTo.getTime()));
    }

    return nextRange;
}
