import { RentalOrderCustomCounterPriceTracking } from "@farmact/model/src/model/RentalOrder";
import React from "react";
import { Input } from "../../../../../inputs/Input/Input";
import "./rentalOrderCustomPriceControl.scss";

interface IRentalOrderCustomPriceControlProps {
    priceTracking: RentalOrderCustomCounterPriceTracking;
    onPriceTrackingChange: (updateData: Partial<RentalOrderCustomCounterPriceTracking>) => void;

    disabled?: boolean;
}

export const RentalOrderCustomPriceControl = (props: IRentalOrderCustomPriceControlProps) => {
    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.valueAsNumber;
        props.onPriceTrackingChange({
            value: Number.isNaN(value) ? null : value,
        });
    };

    return (
        <Input
            className="rental-order-custom-price-control"
            type="number"
            placeholder="Wert eingeben"
            value={props.priceTracking.value ?? ""}
            onChange={handleAmountChange}
            disabled={props.disabled}
        />
    );
};
