import { IonModal, isPlatform } from "@ionic/react";
import clsx from "clsx";
import { ComponentProps } from "react";
import "./farmActModal.scss";

type IonModalProps = ComponentProps<typeof IonModal>;

export function FarmActModal(props: IonModalProps) {
    const { className, onClick, children, ...restProps } = props;
    const modalClassName = clsx(isPlatform("mobile") && "modal-fullscreen", className);

    return (
        <IonModal
            {...restProps}
            className={modalClassName}
            onClick={e => {
                e.stopPropagation();
                onClick?.(e);
            }}>
            {children}
        </IonModal>
    );
}
