import React, { useContext } from "react";

export type Color = "danger" | "warning" | "primary" | "success" | "secondary";

export interface IMessageProps {
    message: string;
    color?: Color;
}

export interface IToastContext {
    onMessage: (message: string, color?: Color) => void;
    dismissMessage: () => void;
}

export const ToastContext = React.createContext<IToastContext>({
    onMessage: () => {},
    dismissMessage: () => {},
});

export function useToastContext() {
    return useContext(ToastContext);
}
