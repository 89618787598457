export enum Currency {
    EUR = "EUR",
    CHF = "CHF",
}

export function getCurrencyName(currency: Currency) {
    switch (currency) {
        case Currency.EUR:
            return "Euro";
        case Currency.CHF:
            return "Schweizer Franken";
    }
}

export function getCurrencySign(currency?: Currency) {
    switch (currency) {
        case Currency.EUR:
            return "€";
        case Currency.CHF:
            return "CHF";
        default:
            return "€";
    }
}

export const FALLBACK_CURRENCY = Currency.EUR;