import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isPlatform } from "@ionic/core";
import { DateInput as MatineDateInput, DatePickerInput } from "@mantine/dates";
import clsx from "clsx";
import { ValidationError } from "../../../util/customHooks/validation/useValidation";
import { DateLike, isValidDate } from "../../../util/timeUtils";
import { useFormControlContext } from "../../FormControlContext/FormControlContext";
import "./dateInput.scss";
import dayjs from "dayjs";

export interface IDateInputProps {
    value?: DateLike | null;
    onChange?: (value: Date | null) => void;

    min?: DateLike;
    max?: DateLike;
    error?: ValidationError;
    /**
     * @default "Datum auswählen"
     */
    placeholder?: string;
    /**
     * @default false
     */
    clearable?: boolean;

    className?: string;
    id?: string;
    disabled?: boolean;
}

export const DateInput = (props: IDateInputProps) => {
    const {
        placeholder = "Datum auswählen",
        clearable = false,
        id: controlledId,
        disabled: controlledDisabled,
        ...restProps
    } = props;

    const { id: uncontrolledId, disabled: uncontrolledDisabled, state } = useFormControlContext();

    const isMobile = isPlatform("mobile");

    const convertedDate = restProps.value ? dayjs(restProps.value).toDate() : null;
    const validatedDate = convertedDate && isValidDate(convertedDate) ? convertedDate : null;

    const sharedProps = {
        id: controlledId || uncontrolledId,
        disabled: controlledDisabled || uncontrolledDisabled,
        valueFormat: "DD.MM.YYYY",
        rightSection: restProps.value ? undefined : <FontAwesomeIcon icon={faCalendarDays} />,
        value: validatedDate,
        onChange: restProps.onChange,
        popoverProps: {
            withinPortal: true,
        },
        placeholder,
        clearable,
        minDate: restProps.min ? dayjs(restProps.min).toDate() : undefined,
        maxDate: restProps.max ? dayjs(restProps.max).toDate() : undefined,
        className: clsx("date-input", restProps.className, {
            "date-input--state-normal": state === "normal",
            "date-input--state-warning": state === "warning",
            "date-input--state-error": state === "error" || props.error,
        }),
        classNames: {
            input: "date-input__input",
            rightSection: "date-input__right-section",
            day: "date-input__day",
        },
    };

    if (isMobile) {
        return <DatePickerInput {...sharedProps} />;
    }

    return <MatineDateInput {...sharedProps} />;
};
