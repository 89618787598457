import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);

/**
 * Computes an array of dates (in the format `YYYY-MM-DD`) that lie between the two argument dates (including start and end)
 * @param startDate string in ISO format
 * @param endDate string in ISO format
 */
export function computeDates(startDate: ISODate | null, endDate: ISODate | null) {
    if (!startDate || !endDate || dayjs(endDate).isBefore(dayjs(startDate))) {
        return [];
    }
    let currentDate = dayjs(startDate);
    const orderDates: string[] = [];
    while (currentDate.isSameOrBefore(endDate, "date")) {
        orderDates.push(currentDate.format("YYYY-MM-DD"));
        currentDate = currentDate.add(1, "day");
    }
    return orderDates;
}

type ISODate = string;